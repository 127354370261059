// import { JackpotGroup } from ";
import { Player } from "../../../constants/converters/player";
import { actions } from "../common";
const baseUrl = "/api/v1/casinos";
export default {
	...actions(baseUrl, Player),
	async loadList({ dispatch, commit, rootGetters, getters }) {
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/${rootGetters.currentCasino}/players/filter`,
					protectedPath: true,
					payload: {
						order_by: getters.pagination.order_by,
						order_as: getters.pagination.order,
						pagination: {
							limit: getters.pagination.per_page,
							page: getters.pagination.page,
						},
					},
				},
				{ root: true }
			);

			let data = response.data.data.map((el) => Player.fromDTO(el));
			commit("list", data);
			return response.data.pagination;
			// commit("pagination", response.data._meta_data.pagination);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load players list", type: "error" },
				{ root: true }
			);

			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async loadDetail(
		{ dispatch, commit, rootGetters },
		{ player_id, casino_id = rootGetters.currentCasino }
	) {
		const url = `${baseUrl}/${casino_id}/players/${player_id}`;
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: url,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = Player.fromDTO(response.data);
			commit("detail", data);
			return true;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching detail from ${url}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Player does not exist.", type: "error" },
				{ root: true }
			);
			commit("detail", {});
			return false;
		}
	},
};
