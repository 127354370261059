import { Round } from "../../../constants/converters/round";
import { Session } from "../../../constants/converters/session";

const baseUrl = "/api/v1/casinos";
export default {
	async loadBigList(
		{ dispatch, commit, rootGetters, getters },
		{ filter } = {}
	) {
		let p = { ...getters.filter, ...filter } ?? {};
		if (
			!Object.prototype.hasOwnProperty.call(p, "games") ||
			!p?.games ||
			p?.games?.length == 0
		) {
			p.games = rootGetters["meta2/allowedGames"]?.map((el) => el.technical);
		}
		try {
			const count = getters.pagination.per_page / 500;
			const response = [];
			for (let i = 1; i < count + 1; i++) {
				response.push(
					dispatch(
						"apiCall/loganPost",
						{
							path: `${baseUrl}/${rootGetters.currentCasino}/sessions/filter`,
							protectedPath: true,
							payload: {
								...p,
								order_by: getters.pagination.order_by,
								order_as: getters.pagination.order,
								pagination: {
									limit: 500,
									page: i,
								},
							},
						},
						{ root: true }
					)
				);
			}
			const res = (await Promise.all(response)).reduce((agg, curr) => {
				agg.push(...curr.data.data);
				return agg;
			}, []);

			let data = res.map((el) => Session.fromDTO(el));

			commit("list", data);
		} catch (e) {
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load sessions list", type: "error" },
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async loadList({ dispatch, commit, rootGetters, getters }, { filter } = {}) {
		let p = { ...getters.filter, ...filter } ?? {};
		if (
			!Object.prototype.hasOwnProperty.call(p, "games") ||
			!p?.games ||
			p?.games?.length == 0
		) {
			p.games = rootGetters["meta2/allowedGames"]?.map((el) => el.technical);
		}
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/${rootGetters.currentCasino}/sessions/filter`,
					protectedPath: true,
					payload: {
						...p,
						order_by: getters.pagination.order_by,
						order_as: getters.pagination.order,
						pagination: {
							limit: getters.pagination.per_page,
							page: getters.pagination.page,
						},
					},
				},
				{ root: true }
			);
			let data = response.data.data.map((el) => Session.fromDTO(el));

			commit("list", data);
			return response.data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			if (e.response.status !== 400) {
				console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
				dispatch(
					"notification/setMessage",
					{ message: "Failed to load sessions list", type: "error" },
					{ root: true }
				);
			}
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
				page: 1,
			});
		}
	},
	async loadListByPlayer(
		{ dispatch, commit, rootGetters, getters },
		{ playerId, filter = {} }
	) {
		const f = { ...getters.filter, ...filter };
		if (
			!Object.prototype.hasOwnProperty.call(f, "games") ||
			!f?.games ||
			f?.games?.length == 0
		) {
			f.games = rootGetters["meta2/allowedGames"]?.map((el) => el.technical);
		}
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/${rootGetters.currentCasino}/players/${playerId}/sessions/filter`,
					protectedPath: true,
					payload: {
						...f,
						order_by: getters.pagination.order_by,
						order_as: getters.pagination.order,
						pagination: {
							limit: getters.pagination.per_page,
							page: getters.pagination.page,
						},
					},
				},
				{ root: true }
			);
			let data = response.data.data.map((el) => Session.fromDTO(el));
			commit("filter", f);
			commit("list", data);
			return response.data.pagination;
			// commit("pagination", response.data._meta_data.pagination);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load players sessions", type: "error" },
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async loadDetail({ dispatch, commit, rootGetters }, sessionId) {
		const url = `${baseUrl}/${rootGetters.currentCasino}/sessions/${sessionId}`;
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: url,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = Session.detailFromDTO(response.data);
			commit("detail", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching detail from ${url}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load session detail", type: "error" },
				{ root: true }
			);
			commit("detail", {});
		}
	},
	async loadRoundsList({ dispatch, commit, rootGetters, getters }, sessionId) {
		try {
			commit('roundsLoading',true)
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/${rootGetters.currentCasino}/sessions/${sessionId}/rounds/filter`,
					protectedPath: true,
					payload: {
						order_by: getters.roundsPagination.order_by,
						order_as: getters.roundsPagination.order,
						pagination: {
							limit: getters.roundsPagination.per_page,
							page: getters.roundsPagination.page,
						},
					},
				},
				{ root: true }
			);
			let data = response.data.data.map((el) =>
				Round.fromDTO(el, rootGetters.currentCasino)
			);
			commit("roundsList", data);
			commit('roundsLoading',false);
			return response.data.pagination;
			// commit("pagination", response.data._meta_data.pagination);
		} catch (e) {
			commit('roundsLoading',false)
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load rounds list", type: "error" },
				{ root: true }
			);
			commit("roundsList", []);
			commit("roundsPagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
};
