import { getters } from "../common";
export default {
	...getters,
	roundsList(state) {
		return state.roundsList.data;
	},
	roundsLoading(state) {
		return state.roundsList.loading;
	},
	roundsFilter(state) {
		return state.roundsList.metadata.filter;
	},
	roundsPagination(state) {
		return state.roundsList.metadata.pagination;
	},
	sessionsPer: (state) => (type, range) => {
		const prevData = state.list.data.filter((el) => {
			return (
				new Date() - new Date(el.start_time) <= 2 * range * 24 * 60 * 60 * 1000
			);
		});
		const data = prevData.filter(
			(el) =>
				new Date() - new Date(el.start_time) <= range * 24 * 60 * 60 * 1000
		);

		if (type === "data") {
			return data;
		} else if (type === "length") {
			return data.length;
		} else {
			return { change: data.length / (prevData.length - data.length) - 1 };
		}
	},
	activeSessions:
		(state, getters) =>
		(range = 1) => {
			const data = getters.sessionsPer("data", range);
			return data.filter((el) => el.end_time == null).length;
		},
	mostPlayed:
		(state, getters) =>
		(range = 1) => {
			let maxKey = null;
			let maxSessions = 0;
			let maxValue = -Infinity;
			let maxNettoKey = null;
			let maxNettoSession = -Infinity;
			let maxNettoRounds = -Infinity;
			let maxNetto = -Infinity;
			const data = getters.sessionsPer("data", range);
			const gameRCount = data.reduce((agg, curr) => {
				agg[`${curr.game_name}`] = agg[`${curr.game_name}`] ?? {};
				agg[`${curr.game_name}`].rounds =
					curr.rounds_count + (agg[`${curr.game_name}`].rounds ?? 0);
				agg[`${curr.game_name}`].netto =
					curr.netto + (agg[`${curr.game_name}`].netto ?? 0);
				agg[`${curr.game_name}`].sessions = agg[`${curr.game_name}`].sessions
					? agg[`${curr.game_name}`].sessions + 1
					: 1;
				return agg;
			}, {});

			for (const key in gameRCount) {
				if (
					Object.prototype.hasOwnProperty.call(gameRCount, key) &&
					typeof gameRCount[key].rounds === "number" &&
					gameRCount[key].rounds > maxValue
				) {
					maxKey = key;
					maxValue = gameRCount[key].rounds;
					maxSessions = gameRCount[key].sessions;
				}
			}

			for (const key in gameRCount) {
				if (
					Object.prototype.hasOwnProperty.call(gameRCount, key) &&
					typeof gameRCount[key].netto === "number" &&
					gameRCount[key].netto > maxNetto
				) {
					maxNettoKey = key;
					maxNetto = gameRCount[key].netto;
					maxNettoRounds = gameRCount[key].rounds;
					maxNettoSession = gameRCount[key].sessions;
				}
			}

			return {
				name: maxKey,
				value: maxValue,
				sessions: maxSessions,
				nettoKey: maxNettoKey,
				nettoMax: maxNetto,
				nettoAvg: maxNetto / maxNettoRounds,
				nettoAvgSession: maxNetto / maxNettoSession,
			};
		},
};
