import axios from "axios";

const state = {
	version: process.env.PACKAGE_VERSION || "0",
	casinoList: {
		data: undefined,
		isLoading: false,
	},
	sectionList: {
		data: undefined,
		isLoading: false,
	},
	gamesList: {
		data: undefined,
		isLoading: false,
	},
	breadCrumbsToTitle: {
		pageName: "",
		pageId: "",
		subPageName: "",
	},
	removeFooters: false,
	currentCasino: {
		id: undefined,
		name: undefined,
	},
	configLoaded: false,
	catUrl: "https://cat-openid.kajotgames.dev",
	loganUrl: "https://zakapp.kajotgames.dev",
	gamesUrl: "https://games.kajotgames.dev",
	storagePrefix: "localhost:",
	historyUrl: "https://history2.kajotgames.dev",
	authVersion: 1,
	environment: "dev",
	useHistory2: true,
};

const getters = {
	getRemoveFooters: (state) => {
		return state["removeFooters"];
	},
	casinoList: (state) => {
		return state["casinoList"];
	},
	sectionList: (state) => {
		return state["sectionList"];
	},
	gamesList: (state) => {
		return state["gamesList"];
	},
	getBreadCrumbs: (state) => {
		return state["breadCrumbsToTitle"];
	},
	getVersion: (state) => {
		return state["version"];
	},
	historyUrl: (state) => {
		return state["historyUrl"];
	},
	loganUrl: (state) => {
		return state["loganUrl"];
	},
	catUrl: (state) => {
		return state["catUrl"];
	},
	gamesUrl: (state) => {
		return state["gamesUrl"];
	},
	storagePrefix: (state) => {
		return state["storagePrefix"];
	},
	currentCasino: (state) => {
		return state["currentCasino"]["id"];
	},
	currentCasinoName: (state) => {
		return state["currentCasino"]["name"];
	},
	getUseHistory2(state) {
		return state.useHistory2;
	},
	getEnvironment(state) {
		return state.environment;
	},
	getAuthVersion(state) {
		return state.authVersion;
	},
};

const actions = {
	async loadConfigFile({ commit, dispatch, state }) {
		if (state.configLoaded) return;
		try {
			const res = await axios.get("/config.json");
			await commit("setUrlConfig", res.data);
		} catch (_) {
			const a = 1;
		}
		commit("setConfigLoaded", true);
	},
	async getCasinoList({ commit, dispatch }, categories = []) {
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/meta/casinos",
				protectedPath: true,
				params: { categories },
				paramsSerializer: {
					indexes: null,
				},
			});
			await commit(
				"setCasinoListData",
				res.data?.sort((el, el2) => el?.casino_id - el2?.casino_id)
			);
		} catch (error) {
			await commit("setCasinoListLoading", false);
		}
	},

	async getSectionList({ commit, dispatch }) {
		commit("setSectionListLoading", true);
		try {
			const res = await dispatch("apiCall/catGet", {
				path: "/api/v1/meta/sections",
				protectedPath: true,
			});
			commit("setSectionListData", res.data.logan);
			commit("setSectionListLoading", false);
		} catch (error) {
			commit("setSectionListLoading", false);
		}
	},

	async getGamesList({ commit, dispatch, rootGetters }) {
		const studios = rootGetters.allowedStudios.map((el) => el.name);
		commit("setGamesListLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/meta/game_studios/games",
				payload: { studios: studios },
				protectedPath: true,
			});
			commit("setGamesListData", res.data);
			commit("setGamesListLoading", false);
		} catch (error) {
			commit("setGamesListLoading", false);
		}
	},

	setBreadCrumbs({ commit }, { pageName = "", pageId = "", subPageName = "" }) {
		commit("setPageName", pageName);
		commit("setPageId", pageId);
		commit("setSubPageName", subPageName);
	},

	async checkCurrentCasino({ commit, dispatch, rootGetters, state }) {
		let notifyMsg = "";
		let notifyType = "";
		let tmpCurrentCasino =
			state.currentCasino.id ??
			JSON.parse(localStorage.getItem(`${state.storagePrefix}KAJOT`))
				?.currentCasino?.id;
		let tmpAllowedCasinos = rootGetters.allowedCasinos;
		let tmpSections = rootGetters.allowedSections;

		if (JSON.stringify(tmpSections) == "{}") return;
		if (tmpAllowedCasinos.length === 0) {
			notifyMsg = "NO CASINOS ASSIGNED";
			notifyType = "error";
			commit("setCurrentCasino", {
				casino_id: undefined,
				website: "undefined.undefined.undefined",
			});
			finish();
			return;
		}

		if (!tmpCurrentCasino) {
			commit("setCurrentCasino", tmpAllowedCasinos[0]);
			finish();
			return;
		}

		const test =
			tmpAllowedCasinos.find((el) => {
				return parseInt(tmpCurrentCasino) === el.casino_id;
			}) ?? tmpAllowedCasinos[0];

		commit("setCurrentCasino", test);
		finish();
		return;

		function finish() {
			commit("setLoginSuccess", true);
			console.info(" LOGIN > notification message > ", notifyMsg);
			dispatch(
				"notification/setMessage",
				{ message: notifyMsg, type: notifyType },
				{ root: true }
			);
		}
	},

	setupCurrentCasino({ commit }, casino) {
		commit("setCurrentCasino", casino);
	},
};

const mutations = {
	setRemoveFooters(state) {
		state.removeFooters = true;
	},
	setConfigLoaded(state, val) {
		state.configLoaded = val;
	},
	setUrlConfig(state, config) {
		state.historyUrl = config.VUE_APP_HISTORY_URL;
		state.loganUrl = config.VUE_APP_LOGAN_URL;
		state.catUrl = config.VUE_APP_CAT_URL;
		state.gamesUrl = config.VUE_APP_GAME_SERVER_URL;
		state.storagePrefix = config.VUE_APP_STORAGE_PREFIX;
		state.history2Url = config.VUE_APP_HISTORY_2_URL;
		state.useHistory2 = config.VUE_APP_USE_HISTORY_2;
		state.environment = config.VUE_APP_ENV ?? "prod";
		state.authVersion = config?.VUE_APP_AUTH_VERSION
			? parseInt(config?.VUE_APP_AUTH_VERSION)
			: state.authVersion;
	},
	setCasinoListData(state, data) {
		state.casinoList.data = data;
	},
	setCasinoListLoading(state, value) {
		state.casinoList.isLoading = value;
	},
	setSectionListData(state, data) {
		state.sectionList.data = data;
	},
	setSectionListLoading(state, value) {
		state.sectionList.isLoading = value;
	},
	setGamesListData(state, data) {
		state.gamesList.data = data;
	},
	setGamesListLoading(state, value) {
		state.gamesList.isLoading = value;
	},
	setPageName(state, value) {
		state.breadCrumbsToTitle.pageName = value;
	},
	setPageId(state, value) {
		state.breadCrumbsToTitle.pageId = value;
	},
	setSubPageName(state, value) {
		state.breadCrumbsToTitle.subPageName = value;
	},
	setCurrentCasino(state, casino) {
		localStorage.setItem(
			`${state.storagePrefix}KAJOT`,
			JSON.stringify({
				currentCasino: { id: casino.casino_id, website: casino.website },
			})
		);
		state.currentCasino.id = parseInt(casino.casino_id);
		state.currentCasino.name = casino.website;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
