import axios from "axios";
// axios.defaults.timeout = 8000;
import jwt_decode from "jwt-decode";
import { constants } from "../../constants/constants";
import router from "../../router";

const state = {
	accessToken: undefined,
	refreshToken: undefined,
	refreshTimeout: undefined,
};

const getters = {
	getAccessToken: (state) => {
		return state["accessToken"];
	},
	accessToken: (state) => {
		return state["accessToken"];
	},
	refreshToken: (state) => {
		return state["refreshToken"];
	},
	accessHeaders: (state, getters, rootGetters) => {
		const userId = rootGetters["auth"]?.user?.data?._id;
		if (!userId) {
			return {
				Authorization: "Bearer " + state["accessToken"],
			};
		} else {
			return {
				Authorization: "Bearer " + state["accessToken"],
				"X-Author": userId,
			};
		}
	},
	refreshHeaders: (state, rootGetters) => {
		return {
			Authorization: "Bearer " + state["refreshToken"],
		};
	},
};

const actions = {
	cancelOnNavigation() {
		requestMap.forEach((controller) => controller.abort());
	},
	async catGet(
		{ getters, dispatch, rootGetters },
		{ path, protectedPath, params }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.get(rootGetters.catUrl + path, {
					headers: getters.accessHeaders,
					params,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			return await apiInstance.get(rootGetters.catUrl + path, { params });
		}
	},

	async catPost(
		{ getters, dispatch, rootGetters },
		{ path, params, protectedPath }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.post(rootGetters.catUrl + path, params, {
					headers: getters.accessHeaders,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			return await apiInstance.post(rootGetters.catUrl + path, params);
		}
	},

	async loganGet(
		{ getters, dispatch, rootGetters },
		{ path, protectedPath, params = {}, paramsSerializer }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.get(rootGetters.loganUrl + path, {
					headers: getters.accessHeaders,
					params: params,
					paramsSerializer,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			return await apiInstance.get(rootGetters.loganUrl + path);
		}
	},

	async loganPost(
		{ getters, dispatch, rootGetters },
		{ path, protectedPath, payload = {}, query = "" }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.post(rootGetters.loganUrl + path, payload, {
					headers: getters.accessHeaders,
					params: query,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			// !!! this is not good need fixing !!!
			// return await axios.post(rootGetters.loganUrl + path, payload, { params: query })
		}
	},

	async loganPut(
		{ getters, dispatch, rootGetters },
		{ path, protectedPath, payload = {}, query = "" }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.put(rootGetters.loganUrl + path, payload, {
					headers: getters.accessHeaders,
					params: query,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			// return await axios.put(rootGetters.loganUrl + path, params)
		}
	},

	async loganPatch(
		{ getters, dispatch, rootGetters },
		{ path, protectedPath, payload = {}, query = "" }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.patch(rootGetters.loganUrl + path, payload, {
					headers: getters.accessHeaders,
					params: query,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			// return await axios.patch(rootGetters.loganUrl + path, params)
		}
	},

	async loganDelete(
		{ getters, dispatch, rootGetters },
		{ path, protectedPath, params = {}, payload = {} }
	) {
		if (protectedPath) {
			const notExpired = await dispatch("checkTokenExpiry");
			if (notExpired) {
				return await apiInstance.delete(rootGetters.loganUrl + path, {
					data: payload,
					params: params,
					headers: getters.accessHeaders,
				});
			} else {
				dispatch("kick", null, { root: true });
			}
		} else {
			console.warn("Token expired");
			// return await axios.put(rootGetters.loganUrl + path, params)
		}
	},

	async checkTokenExpiry({ commit, dispatch, getters, state }, force = false) {
		const accessToken = state["accessToken"];
		const JWT = localStorage.getItem(constants.authLocalStorageKey);
		let session = JSON.parse(JWT);
		if (accessToken == undefined && JWT == null) return false;
		if (
			session &&
			session.access_token &&
			(!session.authVersion || session.authVersion < state.authVersion)
		) {
			console.error("Token expired | Refresh error");
			return false;
		}
		if (!force) {
			if (accessToken != undefined) return await dispatch("expiration");
		}

		await dispatch("loadConfigFile", null, { root: true });
		await dispatch("stateLoginData", session.access_token, { root: true });
		await commit("setAccessToken", session.access_token);
		await commit("setRefreshToken", session.refresh_token);
		const res = await dispatch("expiration");
		if (res || force) {
			await dispatch("meta2/loadStudios", null, { root: true });
			await Promise.all([
				dispatch("getCasinoList", null, { root: true }),
				dispatch("meta2/loadAllowedGames", null, { root: true }),
			]);
			await dispatch("checkCurrentCasino", session.access_token, {
				root: true,
			});
		}
		return res;
	},

	async expiration({ commit, dispatch, rootGetters, getters }) {
		const currentTime = new Date();
		const accessTokenExp = new Date(1000 * jwt_decode(getters.accessToken).exp);
		const refreshTokenExp = new Date(
			1000 * jwt_decode(getters.refreshToken).exp
		);

		if (currentTime < accessTokenExp) {
			clearTimeout(state.refreshTimeout);
			state.refreshTimeout = setTimeout(async () => {
				try {
					await dispatch("refreshTokens");
				} catch (error) {
					console.error(
						" Something went wrong during session refresh \n",
						error
					);
					dispatch("kick", router.currentRoute, { root: true });
				}
			}, Math.max(0, accessTokenExp - currentTime - 12000));
			return true;
		}
		if (currentTime > refreshTokenExp) return false;

		try {
			return await dispatch("refreshTokens");
		} catch (error) {
			console.error(" Something went wrong during session refresh \n", error);
			return false;
		}
	},

	async refreshTokens({ getters, rootGetters, commit, dispatch }) {
		const res = await axios.get(rootGetters.catUrl + "/api/v1/auth/refresh", {
			headers: getters.refreshHeaders,
		});
		commit("setAccessToken", res.data.access_token);
		commit("setRefreshToken", res.data.refresh_token);
		const authVersion = rootGetters.getAuthVersion;
		localStorage.setItem(
			constants.authLocalStorageKey,
			JSON.stringify({ ...res.data, authVersion: authVersion })
		);
		commit("login", jwt_decode(res.data.access_token).identity, {
			root: true,
		});
		dispatch("checkTokenExpiry");
		return true;
	},
};

const mutations = {
	setAccessToken(state, token) {
		state.accessToken = token;
	},
	setRefreshToken(state, token) {
		state.refreshToken = token;
	},
	removeAccessToken(state) {
		state.accessToken = undefined;
	},
	removeRefreshToken(state) {
		state.refreshToken = undefined;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

// Create axios instance to be retried
const apiInstance = axios.create();

// Define maximum retries
const MAX_RETRIES = 3;
const SLEEPER = 200;

// Wait function
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Create object to store requests
const requestMap = new Map();

apiInstance.interceptors.request.use((config) => {
	// Init new AbortController
	const abortController = new AbortController();

	// Check if similar request exists
	if (requestMap.has(config.url)) {
		const prevRequestController = requestMap.get(config.url);
		prevRequestController.abort();
	}
	// Store new controller
	requestMap.set(config.url, abortController);

	// Inject into request config
	config.signal = abortController.signal;

	return config;
});

// Add axios interceptors for reponse
apiInstance.interceptors.response.use(undefined, async (error) => {
	if (axios.isCancel(error)) {
		throw error;
	}
	const { config, response } = error;
	const { method, url, data, params, headers } = config;

	// 408 Request Timeout
	// 425 Too Early
	// 429 Too Many Requests
	// 500 Internal Server Error
	// 502 Bad Gateway
	// 503 Service Unavailable
	// 504 Gateway Timeout
	const retriableStatuses = [408, 425, 429, 500, 502, 503, 504];
	const retriableCodes = ["ECONNRESET"];

	// If error is in 4xx range
	if (
		response &&
		(retriableStatuses.includes(response.status) ||
			retriableCodes.includes(response.code))
	) {
		// Initialize retry counter
		let retries = 0;
		const abortController = new AbortController();

		if (requestMap.has(config.url)) {
			const prevRequestController = requestMap.get(config.url);
			prevRequestController.abort();
		}
		requestMap.set(config.url, abortController);

		const signal = abortController.signal;

		while (!signal.aborted && retries < MAX_RETRIES) {
			retries++;

			await sleep(SLEEPER);

			// Retry for MAX times on a normal axios instance to avoid loop
			try {
				const retryResponse = await axios.request({
					method,
					url,
					data,
					params,
					headers,
					signal,
				});

				// If response returns normal data send them back to function
				return retryResponse;
			} catch (error) {
				// If above max retries throw error into the function
				if (retries === MAX_RETRIES) {
					throw error;
				}
			}
		}
		throw new axios.CanceledError();
	}
	// If error wasnt in 4xx range just Reject promise with the error code.
	return Promise.reject(error);
});
