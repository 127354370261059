import "@/style/hljs.sass";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
// import "./style/dracula.css";

import store from "./store";
import { shouldShortenString } from "./constants/helpers";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import * as fns from "date-fns";

fns.setDefaultOptions({ weekStartsOn: 1 });
hljs.registerLanguage("json", json);

Vue.filter("Date", (val, options) => {
	if (!val) return "N/a";
	const defaultOptions = {
		timeZone: "UTC",
		timeZoneName: "short",
	};
	if (val == undefined || val == null) {
		return "N/A";
	}
	return new Date(val)
		.toLocaleString(navigator.language, {
			...defaultOptions,
			...options,
		})
		.replaceAll(". ", ".");
	// return format(new Date(val), formatStr);
});

Vue.filter(
	"Percentage",
	/**
	 *
	 * @param {Number} val
	 * @param {Intl.NumberFormatOptions} options
	 */
	(val = 0, options = {}) => {
		let res = val;
		const mixedOptions = {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			style: "percent",
			...options,
		};
		return res.toLocaleString(navigator.language, mixedOptions);
	}
);

Vue.filter(
	"CompactNumber",
	/**
	 *
	 * @param {Number} val
	 * @param {Intl.NumberFormatOptions} options
	 */
	(val, options = {}) => {
		let res = val;
		if (!val) res = 0;
		const defaultOptions = {
			notation: "compact",
		};
		let formatter = Intl.NumberFormat("en", { ...defaultOptions, ...options });
		return formatter.format(res);
	}
);
Vue.filter("Replace", (val, a, b) => val.replace(a, b));
Vue.filter(
	"Number",
	/**
	 *
	 * @param {Number} val
	 * @param {Intl.NumberFormatOptions} options
	 */
	(val, options = {}) => {
		let res = val;
		if (!val) res = 0;
		if (options.minor_units) {
			options.minimumFractionDigits = options.minor_units;
			options.maximumFractionDigits = options.minor_units;
		}
		const mixedOptions = {
			currency: "EUR",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			style: "currency",
			...options,
		};
		try {
			const formatter = new Intl.NumberFormat(
				options.language ?? navigator.language,
				mixedOptions
			);

			return formatter.format(res);
		} catch (error) {
			const formattedAmount = new Intl.NumberFormat(
				options.language ?? navigator.language,
				{ ...mixedOptions, currency: "EUR", currencyDisplay: "code" }
			).format(res);

			return formattedAmount.replace("EUR", mixedOptions.currency);
		}
	}
);

Vue.filter("CurrencyFromCredits", (val) => {
	if (!val) return 0;
	val = val / 100;
	return val;
});

Vue.filter("SpaceOut", (val, groupLength = 3) => {
	if (!val) return "";
	const [valStr, decimal] = val.toString().split(".");
	let res = "";
	for (let i = 0; i < valStr.length; i += groupLength) {
		res = `${res} ${valStr.substr(-i, groupLength)}`;
	}
	return [res, decimal].join(".");
});

Vue.filter("Capitalize", (val) => {
	if (!val) return "";
	return val?.charAt(0).toUpperCase() + val.slice(1);
});

Vue.filter("Placeholder", (val, placeholder = "-") => {
	if (val === null || val === undefined || val === "") return placeholder;
	return val;
});

Vue.filter("DecimalPoints", (val, decimalPoints = 2) =>
	val.toFixed(decimalPoints)
);
Vue.filter(
	"ellipsis",
	(val, mode = "m", ellipsisChar = ".", showCount = 5, ellipsisCount = 3) => {
		if (!shouldShortenString(val, mode, showCount, ellipsisCount)) return val;
		const start = val.substr(0, showCount);
		const end = val.substr(-showCount);
		const ellipsis = "".padStart(ellipsisCount, ellipsisChar);
		let res = ellipsis;
		if (mode === "e" || mode === "m") {
			res = `${start}${res}`;
		}
		if (mode === "s" || mode === "m") {
			res = `${res}${end}`;
		}
		return res;
	}
);

Vue.filter("highlight", (val, highlight) => {
	if (!highlight) return val;
	// If highlight is array create OR regex
	const regx = new RegExp(
		`${Array.isArray(highlight) ? highlight.join("|") : highlight}`,
		"gm"
	);
	// Use callback to figure out what part of the regex matched, return string with k-highlight class
	return val?.toString().replaceAll(regx, (match) => {
		return `<span class=k-highlight>${match}</span>`;
	});
});

Sentry.init({
	Vue,
	dsn: process.env.DSN,
	environment: process.env.ENVIRONMENT,
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
	],
});

new Vue({
	el: "#app",
	store,
	router,
	vuetify,
	render: (h) => h(App),
});
// eslint-disable-next-line no-undef
