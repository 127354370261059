import { mutations } from "../common";
export default {
	...mutations,
	roundsList(state, payload) {
		state.roundsList.data = payload;
	},
	roundsLoading(state, payload) {
		state.roundsList.loading = payload;
	},
	roundsPagination(state, payload) {
		state.roundsList.metadata.pagination = payload;
	},
	roundsFilter(state, payload) {
		state.roundsList.metadata.filter = payload;
	},
};
