export default {
	list(state, payload) {
		state.list.data = payload;
	},
	pagination(state, payload) {
		state.list.metadata.pagination = payload;
	},
	filter(state, payload) {
		state.list.metadata.filter = payload;
	},
	detail(state, payload) {
		state.detail = payload;
	},
};
