import { deflate, inflate } from "pako";

export function userFlagsSome(userPermissions, ...requiredFlags) {
	return (
		(userPermissions & requiredFlags.reduce((prev, next) => prev | next, 0)) > 0
	);
}

export function userFlagsAll(userPermissions, ...requiredFlags) {
	let req = requiredFlags.reduce((prev, next) => prev | next, 0);
	return (userPermissions & req) === req;
}

export function rgbaFromTheme(colorName, opacity) {
	const partOpacity = opacity * (255 / 100);
	const hexpacity = partOpacity.toString(16);
	return `${this.$vuetify.theme.currentTheme[`${colorName}`]}${hexpacity}`;
}

export function isNullish(src) {
	return src === undefined || src === null;
}

/**
 * @param  {number} previousCasino
 * @param  {number} currentCasino
 * @param  {number[] | undefined} selected array of selected items from filter object
 * @returns {number[]}
 */
export function casinoChanged(
	previousCasino,
	currentCasino,
	selected = [currentCasino]
) {
	if (
		selected?.length === 1 &&
		selected[0] === previousCasino &&
		previousCasino !== currentCasino
	) {
		return [currentCasino];
	}
	return selected;
}

export function isIOS() {
	return /(iPad|iPhone|iPod|Mac)/g.test(navigator.userAgent);
}

export function isMobile() {
	return this.$vuetify.breakpoint.smAndDown;
}

export function saveAsFile(data, name) {
	const blob = new Blob([JSON.stringify(data, null, 2)]);
	const link = document.createElement("a");
	link.download = `${name}-export.json`;
	link.href = window.URL.createObjectURL(blob);
	link.click();
}

/**
 *
 * @param  {...{key:(string|string[]),val:number}} def
 * @returns {boolean}
 */
export function permControl(permissions_new, ...def) {
	for (let rule of def) {
		for (let key of [].concat(rule.key)) {
			const permVal = key
				.split(".")
				.reduce((prev, next) => prev?.[next], permissions_new);
			if ((permVal & rule.val) !== 0) return true;
		}
	}
	return false;
}

/**
 *
 * @param {string} val string to check, if it is viable for shortening
 * @param {"m" | "s" | "e"} mode shortening mode: where should ellipsis appear
 * @param {number} showCount how manz chars should be displayed before / after ellipsis
 * @param {number} ellipsisCount how many ellipsis chars should be displayed
 * @returns {boolean} returns true if ellipsed string is shorter than original string
 */
export function shouldShortenString(
	val,
	mode = "m",
	showCount = 5,
	ellipsisCount = 3
) {
	return showCount * (mode === "m" ? 2 : 1) + ellipsisCount < val?.length;
}

export function objFromZlib(src) {
	const compressedUint8Array = Buffer.from(src, "base64");
	const dataUint8Array = inflate(compressedUint8Array, { to: "string" });

	return JSON.parse(dataUint8Array);
}

export function objToZlib(obj) {
	const dataUint8Array = JSON.stringify(obj);
	const compressedUint8Array = deflate(dataUint8Array);
	const b64 = Buffer.from(compressedUint8Array).toString("base64");

	return b64;
}

export function findDelimiter(val) {
	if (!val) return;
	const valLines = val.split(/\r?\n/);

	// List of potantial delimiter characters
	const potentialDelimiters = [",", ";", "\t", "|"];

	// Init counters
	const perLineCount = [];
	const sameLines = potentialDelimiters.reduce((agg, next) => {
		agg[`${next}`] = 0;
		return agg;
	}, {});

	// Iterate trough potential delimiters and find out their summary and per-line occurences
	for (const [i, v] of valLines.entries()) {
		perLineCount[i] = {};
		for (const delimiter of potentialDelimiters) {
			const count = (v.match(new RegExp(`\\${delimiter}`, "g")) || []).length;
			perLineCount[i][delimiter] = count;
			if (i != 0 && count != 0 && count === perLineCount[0][delimiter]) {
				sameLines[delimiter]++;
			}
		}
	}

	//Find the delimiter with the highest count
	let selectedDelimiter = "";
	let maxCount = 0;
	for (const delimiter in sameLines) {
		if (sameLines[delimiter] > maxCount) {
			maxCount = sameLines[delimiter];
			selectedDelimiter = delimiter;
		}
	}

	if (selectedDelimiter) {
		return selectedDelimiter;
	} else {
		return undefined;
	}
}

/**
 * @callback onProgressCB
 * @param {Number} val
 * @returns {void}
 */
/**
 *
 * @param {Sting} strData
 * @param {String} [strDelimiter]
 * @param {onProgressCB} [onProgress]
 * @returns {String[][]}
 */
export function CSVToArray(strData, strDelimiter, onProgress) {
	try {
		strDelimiter = strDelimiter ?? findDelimiter(strData) ?? ",";
		var objPattern = new RegExp(
			// Delimiters.
			"(\\" +
				strDelimiter +
				"|\\r?\\n|\\r|^)" +
				// Quoted fields.
				'(?:"([^"]*(?:""[^"]*)*)"|' +
				// Standard fields.
				'([^"\\' +
				strDelimiter +
				"\\r\\n]*))",
			"gi"
		);

		// Create an array to hold our data. Give the array
		// a default empty first row.
		var arrData = [[]];

		// Create an array to hold our individual pattern
		// matching groups.
		var arrMatches = null;

		// Keep looping over the regular expression matches
		// until we can no longer find a match.

		while ((arrMatches = objPattern.exec(strData)) !== null) {
			// Get the delimiter that was found.
			var strMatchedDelimiter = arrMatches[1];

			onProgress?.(arrMatches.index / arrMatches.input.length);

			// Check to see if the given delimiter has a length
			// (is not the start of string) and if it matches
			// field delimiter. If id does not, then we know
			// that this delimiter is a row delimiter.
			if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
				// Since we have reached a new row of data,
				// add an empty row to our data array.
				arrData.push([]);
			}

			var strMatchedValue;

			// Now that we have our delimiter out of the way,
			// let's check to see which kind of value we
			// captured (quoted or unquoted).
			if (arrMatches[2]) {
				// We found a quoted value. When we capture
				// this value, unescape any double quotes.
				strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
			} else {
				// We found a non-quoted value.
				strMatchedValue = arrMatches[3];
			}

			// Now that we have our value string, let's add
			// it to the data array.
			arrData[arrData.length - 1].push(strMatchedValue);
		}
		return arrData;
	} catch (e) {
		return e;
	}

	// Return the parsed data.
}

/**
 *
 * @param {String[][]} array
 * @param {String} [delimiter]
 * @param {onProgressCB} [onProgress]
 * @returns
 */
export function arrayToCSV(array, delimiter = ",", onProgress) {
	let totalIndex = 0;
	const totalItems = array
		.map((el) => el.length)
		.reduce((prev, next) => prev + next, 0);
	return array
		.map((arrayInner) => {
			return arrayInner
				.map((el) => {
					if (el === null || el === undefined) return "";
					let res = el.toString().replaceAll('"', '""');
					if (res.includes(delimiter) || res.includes('"')) {
						res = `"${res}"`;
					}
					onProgress?.(totalIndex / totalItems);
					totalIndex++;
					return res;
				})
				.join(delimiter);
		})
		.join("\n");
}
export function lerp(min, max, t) {
	return min + t * (max - min);
}

export function clone(obj) {
	try {
		return JSON.parse(JSON.stringify(obj));
	} catch (error) {
		return undefined;
	}
}

/**
 *
 * @param {Number} val Numeric value in minor units to be converted to regular currency
 * @param {Number?} minorUnits How many decimal places is used for the smallest unit of currency
 * @returns Value converted from minor units to regular currency
 */
export function fromMinorUnits(val, minorUnits = 2) {
	return val / 10 ** minorUnits;
}

export function generateHash(length = 16, base = 36) {
	return (Math.random() * base)
		.toString(base)
		.replace(".", "")
		.substring(0, length);
}

export function arrayify(el) {
	if (!Array.isArray(el)) {
		return [el];
	}
	return el;
}

export function toLocalDate(date) {
    const [day, month, year, hours, minutes] = [
        date.getUTCDate(),
        date.getUTCMonth() + 1,
        date.getUTCFullYear(),
        date.getUTCHours(),
        date.getUTCMinutes()
    ].map(unit => String(unit).padStart(2, '0'));

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}
